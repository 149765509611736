import axios from 'axios';
import {REQUESTS_FINCEN_OFFERS_ENDPOINT, REQUESTS_FINCEN_PUBLIC_OFFERS_ENDPOINT} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import FRSO_DTO from "@/dto/request/fincen/frso/FRSO_DTO";

class FinCENService {

    createOffers() {
        return axios.put(REQUESTS_FINCEN_OFFERS_ENDPOINT, null, {headers: authHeader()})
    }

    getOfferByPublicId(id: string) {
        return axios.get<FRSO_DTO>(`${REQUESTS_FINCEN_PUBLIC_OFFERS_ENDPOINT}/${id}`);
    }

    updateOfferByPublicId(id: string, payload: FRSO_DTO) {
        return axios.put(`${REQUESTS_FINCEN_PUBLIC_OFFERS_ENDPOINT}/${id}`, payload);
    }

    getOfferById(id: number) {
        return axios.get<FRSO_DTO>(`${REQUESTS_FINCEN_OFFERS_ENDPOINT}/${id}`, {headers: authHeader()});
    }

    updateOfferById(id: number, payload: FRSO_DTO) {
        return axios.put(`${REQUESTS_FINCEN_OFFERS_ENDPOINT}/${id}`, payload, {headers: authHeader()});
    }

}

export default new FinCENService();